/* ----- color ----- */
@use "sass:math";

$adaptive-primary: #0740e4;
$adaptive-primary-op40: rgba($adaptive-primary, 0.40);
$adaptive-primary-op30: rgba($adaptive-primary, 0.30);
$adaptive-primary-op20: rgba($adaptive-primary, 0.20);
$adaptive-primary-op10: rgba($adaptive-primary, 0.10);
$adaptive-primary-op03: rgba($adaptive-primary, 0.03);

$adaptive-secondary: rgb(255, 103, 62);
$adaptive-secondary-op40: rgba($adaptive-secondary, 0.40);
$adaptive-secondary-op30: rgba($adaptive-secondary, 0.30);
$adaptive-secondary-op20: rgba($adaptive-secondary, 0.20);
$adaptive-secondary-op10: rgba($adaptive-secondary, 0.10);
$adaptive-secondary-op03: rgba($adaptive-secondary, 0.03);

$adaptive-green: #83B100;
$adaptive-green-op40: rgba($adaptive-green, 0.40);
$adaptive-green-op30: rgba($adaptive-green, 0.30);
$adaptive-green-op20: rgba($adaptive-green, 0.20);
$adaptive-green-op10: rgba($adaptive-green, 0.10);
$adaptive-green-op03: rgba($adaptive-green, 0.03);

$adaptive-black: #000000;
$adaptive-black-op60: rgba($adaptive-black, 0.60);
$adaptive-black-op40: rgba($adaptive-black, 0.40);
$adaptive-black-op20: rgba($adaptive-black, 0.20);
$adaptive-black-op10: rgba($adaptive-black, 0.10);
$adaptive-black-op05: rgba($adaptive-black, 0.05);

$adaptive-white: #ffffff;
$adaptive-white-op50: rgba($adaptive-white, 0.50);
$adaptive-white-op40: rgba($adaptive-white, 0.40);
$adaptive-white-op30: rgba($adaptive-white, 0.30);
$adaptive-white-op20: rgba($adaptive-white, 0.20);
$adaptive-white-op12: rgba($adaptive-white, 0.12);

$adaptive-gray-10: #101010;
$adaptive-gray-3c: #3c3c3c;
$adaptive-gray-55: #555555;
$adaptive-gray-70: #707070;
$adaptive-gray-93: #939393;
$adaptive-gray-a7: #a7a7a7;
$adaptive-gray-c2: #c2c2c2;
$adaptive-gray-d9: #d9d9d9;
$adaptive-gray-ed: #ededed;
$adaptive-gray-f8: #f8f8f8;


@mixin color_factory ($name,$color) {
  .#{$name}{color: $color;}
}
@include color_factory ('primary',$adaptive-primary);
@include color_factory ('secondary',$adaptive-secondary);
@include color_factory ('green',$adaptive-green);
@include color_factory ('black',$adaptive-black);
@include color_factory ('white',$adaptive-white);
@include color_factory ('gray_10',$adaptive-gray-10);
@include color_factory ('gray_3c',$adaptive-gray-3c);
@include color_factory ('gray_55',$adaptive-gray-55);
@include color_factory ('gray_70',$adaptive-gray-70);
@include color_factory ('gray_93',$adaptive-gray-93);
@include color_factory ('gray_a7',$adaptive-gray-a7);
@include color_factory ('gray_c2',$adaptive-gray-c2);
@include color_factory ('gray_d9',$adaptive-gray-d9);
@include color_factory ('gray_ed',$adaptive-gray-ed);
@include color_factory ('gray_f8',$adaptive-gray-f8);

/* ----- font-size ----- */
@for $i from 8 to 100 {
  .fs#{$i} {font-size: 1px * $i !important;}
}

/* ----- width ----- */
$adaptive-width-mobile: 600px;

/* ----- margin ----- */
@mixin margin_factory($end) {
  // $end : @for end value

  @for $i from -$end to $end {
    .m#{$i} {margin: 1px * $i !important;}

    .ms#{$i} {
      margin: {
        left: 1px * $i !important;;
        right: 1px * $i !important;;
      }
    }

    .mv#{$i}{
      margin: {
        top: 1px * $i !important;;
        bottom: 1px * $i !important;;
      }
    }

    .mt#{$i}{margin-top: 1px * ($i) !important;}
    .ml#{$i}{margin-left: 1px * ($i) !important;}
    .mr#{$i}{margin-right: 1px * ($i) !important;}
    .mb#{$i}{margin-bottom: 1px * ($i) !important;}
  }
}
@include margin_factory(200);


/* ----- padding ----- */
@mixin padding_factory($end) {
  // $end : @for end value

  @for $i from 0 to $end {
    .p#{$i} {padding: 1px * $i}

    .ps#{$i} {
      padding: {
        left: 1px * $i;
        right: 1px * $i;
      }
    }

    .pv#{$i}{
      padding: {
        top: 1px * $i;
        bottom: 1px * $i;
      }
    }

    .pt#{$i}{padding-top: 1px * ($i)}
    .pl#{$i}{padding-left: 1px * ($i)}
    .pr#{$i}{padding-right: 1px * ($i)}
    .pb#{$i}{padding-bottom: 1px * ($i)}
  }
}
@include padding_factory(200);

/* ----- border ----- */
@mixin radius_factory($end) {
  // $end : @for end value

  @for $i from 0 to $end {
    .rd#{$i} {border-radius: 1px * $i}

    .rds#{$i} {
      border-radius: {
        left: 1px * $i;
        right: 1px * $i;
      }
    }

    .rdv#{$i}{
      border: {
        top: 1px * $i;
        bottom: 1px * $i;
      }
    }

    .rdt#{$i}{margin-top: 1px * ($i)}
    .rdl#{$i}{margin-left: 1px * ($i)}
    .rdr#{$i}{margin-right: 1px * ($i)}
    .rdb#{$i}{margin-bottom: 1px * ($i)}
  }
}

@include radius_factory(200);

/* ----- col ----- */
@mixin col_factory($col: 1, $gap: 0) {
  $var :  1px * $gap;
  
  .col#{$col} {
    width: calc(100% + #{$var}) !important;
    .col_item{
      display: inline-block !important;
      display: inline-flex !important;
      width: math.div(100%, $col);
      padding-right: 1px * $gap;
      vertical-align: top;
    }
  }
}

@include col_factory(2, 8); //col2 -> 간격 8px
@include col_factory(3, 8); //col2 -> 간격 8px
@include col_factory(4, 8); //col2 -> 간격 8px