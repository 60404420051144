i[class*=icon_] {
	display: inline-block;
	position: relative;
	background-color: transparent;
	font-size: 0;
	
	&:after{
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat:no-repeat;
		background-size: auto 100%;
	}
	
	// size
	&.size_12{
		width: 12px;
		height: 12px;
	}
	&.size_16{
		width: 16px;
		height: 16px;
	}
	&.size_20{
		width: 20px;
		height: 20px;
	}
	&.size_24{
		width: 24px;
		height: 24px;
	}
	&.size_32{
		width: 32px;
		height: 32px;
	}
	&.size_48{
		width: 48px;
		height: 48px;
	}
	&.size_104{
		width: 104px;
		height: 104px;
	}

	// shape
	&.shape_circle:after{
		border-radius: 50%;
	}
	&.shape_square:after{
	
	}
	&.shape_round:after{
		border-radius: 5px;
	}
}

@mixin icon-image($className, $fileName, $pos1-x: 0, $pos1-y: 0, $pos2-x: 0, $pos2-y: 0, $size-w: auto, $size-h: 100%){
	.#{$className} {
		&:after{
			background-image:url('/assets/images/icon/#{$fileName}');
			background-position-x: $pos1-x;
			background-position-y: $pos1-y;
			background-size: $size-w $size-h;
		}
		&.on:after{
			background-position-x: $pos2-x;
			background-position-y: $pos2-y;
		}
	}
}

@include icon-image('icon_header_logo', 'icon_header_logo.svg');
@include icon-image('icon_header_back', 'icon_header_back.svg');
@include icon-image('icon_header_close', 'icon_header_close.svg');
@include icon-image('icon_password_eye', 'icon_password_eye.svg', -20px, 0, 0, 0);
@include icon-image('icon_social_google', 'icon_social_google.svg');
@include icon-image('icon_social_kakao', 'icon_social_kakao.svg');
@include icon-image('icon_social_apple', 'icon_social_apple.svg');
@include icon-image('icon_social_naver', 'icon_social_naver.svg');
@include icon-image('icon_arrow_right', 'icon_arrow_right.svg');
@include icon-image('icon_arrow_right_small', 'icon_arrow_right_small.svg');
@include icon-image('icon_triangle_bang', 'icon_triangle_bang.svg');
@include icon-image('icon_circle_bang', 'icon_circle_bang.svg');
@include icon-image('icon_home', 'icon_home.svg');

