/*
 * dependency : none
 */

/* ----- reset ----- */
html, body {
  word-break: break-word;
  line-height: 24px;
  font-family: Noto Sans KR, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #101010;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, time, figure, article, nav, header, footer, hgroup, video, audio, aside, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  font-weight: inherit;
  font-style: inherit;
}
*, :after, :before {
  margin: 0;
  padding: 0;
  border: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ol, ul {
  list-style: none;
}
a {
  color: #101010;
  text-decoration: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption, th, td {
  text-align: left;
  font-weight: normal;
}
figure, article, header, footer, nav, hgroup, video, audio, aside, main {
  display: block;
}


/* ----- form ---- */
label {
  display: inline-block;
}
button,
input,
textarea {
  -webkit-appearance: none;
  display: inline-block;
  border:0;
  border-radius:0;
  background: transparent;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  font-family: inherit;
  font-family: Noto Sans KR, sans-serif;
}
input[type=radio],
input[type=checkbox]{
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}



/* ----- mobile ----- */
html {
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

body, textarea:focus, input:focus, a:focus {
  -webkit-tap-highlight-color: rgba(135, 135, 135, .08);
}

body {
  -webkit-touch-callout: none;
}


