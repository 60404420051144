@import './_font.scss';
@import './_reset.scss';
@import './_variable.scss';
@import './_icon.scss';

/* ----- common ----- */
.not_scroll{overflow: hidden;}
.ellip {overflow: hidden;display: inline-block;max-width: 100%;white-space: nowrap;word-wrap: normal;text-overflow: ellipsis;vertical-align: middle;}
.blind {overflow: hidden;position: absolute;margin: -1rem;width: 0rem;height: 0rem;clip: rect(0, 0, 0, 0);}

/* ----- display ----- */
.none   {display: none;}
.none_i {display: none;}
.block  {display: block;}
.inline {display: inline;}

/* ----- float ----- */
.fl     {float: left;}
.fr     {float: right;}
.fle    {float: left;}
.fri    {float: right;}
.clear  {clear: both;}

/* ----- margin | padding ----- */
.ps_auto {
    padding: {left: auto !important; right: auto !important;}
}

.ms_auto {
    display: block !important;
    margin: {left: auto !important; right: auto !important;}
}

/* ----- typography ----- */
.underline {text-decoration: underline;}
.align_L{text-align: left;}
.align_C{text-align: center;}
.align_R{text-align: right;}

.heading_32B    {line-height: 48px; font-size: 32px; letter-spacing: -0.02em; font-weight: 600; }
.heading2_28B   {line-height: 42px; font-size: 28px; letter-spacing: -0.02em; font-weight: 600; }

.title_24B      {line-height: 36px; font-size: 24px; letter-spacing: -0.02em; font-weight: 600; }

.subtitle_20R   {line-height: 30px; font-size: 20px; letter-spacing: -0.02em; font-weight: 400;}
.subtitle_20M   {line-height: 30px; font-size: 20px; letter-spacing: -0.02em; font-weight: 500;}
.subtitle_20B   {line-height: 30px; font-size: 20px; letter-spacing: -0.02em;font-weight: 600;}

.subtitle_18R   {line-height: 27px; font-size: 18px; letter-spacing: -0.02em; font-weight: 400;}
.subtitle_18M   {line-height: 27px; font-size: 18px; letter-spacing: -0.02em; font-weight: 500;}
.subtitle_18B   {line-height: 27px; font-size: 18px; letter-spacing: -0.02em;font-weight: 600;}

.body1_18R      {line-height: 27px; font-size: 18px; letter-spacing: -0.02em; font-weight: 400;}
.body1_18M      {line-height: 27px; font-size: 18px; letter-spacing: -0.02em; font-weight: 500;}
.body1_18B      {line-height: 27px; font-size: 18px; letter-spacing: -0.02em;font-weight: 600;}

.body2_16R      {line-height: 24px; font-size: 16px; letter-spacing: -0.02em; font-weight: 400;}
.body2_16M      {line-height: 24px; font-size: 16px; letter-spacing: -0.02em; font-weight: 500;}
.body2_16B      {line-height: 24px; font-size: 16px; letter-spacing: -0.02em;font-weight: 600;}

.body3_14R      {line-height: 21px; font-size: 14px; letter-spacing: -0.02em; font-weight: 400;}
.body3_14M      {line-height: 21px; font-size: 14px; letter-spacing: -0.02em; font-weight: 500;}
.body3_14B      {line-height: 21px; font-size: 14px; letter-spacing: -0.02em;font-weight: 600;}

.caption1_12R   {line-height: 18px; font-size: 12px; letter-spacing: -0.02em; font-weight: 400;}
.caption1_12M   {line-height: 18px; font-size: 12px; letter-spacing: -0.02em; font-weight: 500;}
.caption1_12B   {line-height: 18px; font-size: 12px; letter-spacing: -0.02em;font-weight: 600;}

.caption2_10R   {line-height: 15px; font-size: 10px; letter-spacing: -0.02em; font-weight: 400;}
.caption2_10M   {line-height: 15px; font-size: 10px; letter-spacing: -0.02em; font-weight: 500;}



.heading_32B [class*=icon_],
.heading2_28B [class*=icon_],
.title_24B [class*=icon_],
.subtitle_20R [class*=icon_],
.subtitle_20M [class*=icon_],
.subtitle_20B [class*=icon_],
.subtitle_18R [class*=icon_],
.subtitle_18M [class*=icon_],
.subtitle_18B [class*=icon_],
.body1_18R [class*=icon_],
.body1_18M [class*=icon_],
.body1_18B [class*=icon_],
.body2_16R [class*=icon_],
.body2_16M [class*=icon_],
.body2_16B [class*=icon_],
.body3_14R [class*=icon_],
.body3_14M [class*=icon_],
.body3_14B [class*=icon_] {
    vertical-align: middle;
}
.caption1_12R [class*=icon_],
.caption1_12M [class*=icon_],
.caption1_12B [class*=icon_],
.caption2_10R [class*=icon_],
.caption2_10M [class*=icon_] {
    vertical-align: text-top;
}
