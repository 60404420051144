
.comp{
	display: inline-block;
	vertical-align: top;
	font-size:0;
	text-align: center;
	
	i{
		display: block;
		width: 48px;
		height: 48px;
		overflow: hidden;
		
		&__text{
			display: block;
			font-size:14px;
		}
	}
}


