.layout{
	&:after{
		opacity: .05;
		content:"";
		display: block;
		position: absolute;
		top: 7px;
		right: -69px;
		z-index:10;
		width: 312px;
		height: 300px;
		background: url(/assets/images/icon/icon_symbol.svg) no-repeat 0 0;
		background-size: auto 100%;
	}
}
