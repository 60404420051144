.comp{
	opacity: 0;
	visibility: hidden;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
	&.open{
		opacity: 1;
		visibility: visible;
		z-index:999;
		
		.sheet {
			transform: translateY(0);
		}
		
		.sheet_mask{
			opacity: 1;
		}
	}
	
	.sheet{
		display: block;
		display: flex;
		-webkit-display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		background: #ffffff;
		transform: translateY(100%);
		transition: all 0.45s ease-out;
		
		&__header{
			width: 100%;
			padding: 8px 0 16px;
			
			.handlebar{
				display: block;
				width: 64px;
				margin: 0 auto;
				padding-top: 7px;
				padding-bottom: 8px;
				text-align: center;
				
				.bar{
					display: block;
					width: 40px;
					height: 4px;
					margin: 0 auto;
					border-radius: 10px;
					background: #c2c2c2;
				}
			}
		}
		
		&__container{
			width: 100%;
			overflow: hidden;
			overflow-y: auto;
			max-width:600px;
			min-height: 100px;
			margin:0 auto;
			
			.inner{
				padding: 0 16px 14px 16px;
			}
		}
		
	}
	
	.sheet_mask{
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: rgba(0,0,0,.6);
		transition: all 0.45s ease-out;
	}
	
	
	@media (orientation: landscape) {
		.sheet {
			max-height: 70vh !important;
			&__container{
			
			}
		}
	}
}

