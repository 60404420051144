label {
	display: block;
	width: 100%;
	margin-bottom: 2px;
	font-size: 12px;
	line-height:18px;
	color: #a7a7a7;
}

// input
.input {
	display: block;
	display: flex;
	-webkit-display: flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	position: relative;
	
	.input_inner{
		display: block;
		display: flex;
		-webkit-display: flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		align-items: flex-end;
		-webkit-align-items: flex-end;
		position: relative;
		width: 100%;
	}
	
	[class*=icon_]{
		width:32px;
		height:32px;
		background-color: #fff;
	}
}

// type = check
.check_type {
	label {
		color: #939393;
		font-size: 16px;
		
		span{
			display: inline-block;
			position: relative;
			padding-left: 28px;
			
			&:before {
				content: "";
				position: absolute;
				top: 50%;
				left:0;
				width: 20px;
				height: 20px;
				vertical-align: top;
				transform:translateY(-50%);
			}
		}
	}
	
	input[type=checkbox] {
		overflow: hidden;
		position: absolute;
		width: 0rem;
		height: 0rem;
		clip: rect(0, 0, 0, 0);
		
		+ label span:before {
			background-image: url(/assets/images/icon/icon_check_circle.svg);
			background-size: auto 20px;
			background-position: -60px 0;
		}
		
		&:checked {
			+label span:before {
				background-position: 0px 0;
			}
		}
	}
	
	& + .check_type{
		margin-top: 16px;
	}
}

// type = text, password, tel ...
.text_type {
	label {
		display: block;
		width: 100%;
		margin-bottom: 2px;
		color: #a7a7a7;
	}
	
	input {
		flex-grow: 1;
		-webkit-flex-grow: 1;
		width: calc(100% - 120px);
		height: 36px;
		border-bottom: 2px solid #ededed;
		
		&::-webkit-input-placeholder {
			color: #C2C2C2;
			font-weight: 400 !important;
		}
		&::-moz-placeholder {
			color: #C2C2C2;
			font-weight: 400 !important;
		}
		&::-ms-input-placeholder {
			color: #C2C2C2;
			font-weight: 400 !important;
		}
		
		~ button:not([class*=icon_]){
			flex-grow: 0;
			-webkit-flex-grow: 0;
			width: auto;
			min-width: 109px;
			height: 44px;
			margin-left: 8px;
			line-height:43px;
			font-size:14px;
			font-weight: 500;
			
			@media (max-width:359px) {
				min-width:100%;
				margin-left:0;
				margin-top: 8px;
			}
		}
		
		&:disabled,
		&[disabled]{opacity: 0.3;}
		
		+ p{
			margin-top: 8px;
		}
		
	}
	
	&.input_focus {
		label {
			color: #101010;
		}
		
		input:not(disabled) {
			border-bottom-color: #101010;
			color: #101010;
		}
	}
	
	&.text {
		margin-bottom: 32px;

		&:has(+ p) {
			margin-bottom: 0;
		}
	
		& + p {
			margin:10px 0 32px;
			color: #a7a7a7;
			font-size: 12px;
		}
	}
	
	&.password {
		margin-bottom: 32px;
		
		&:not(.input_focus){
			[class*=icon_]{
				opacity: 0;
				touch-action: none;
			}
		}
	}
	
	&.tel {
		margin-bottom: 12px;
	}
}

// type = radio
.radio_type {
	width:100%;
	margin-top: 6px;
	
	input[type=radio] {
		& + label{
			width: 100%;
			height: 55px;
			line-height:53px;
			border:1px solid #EDEDED;
			border-radius: 5px;
			color:#707070;
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			color:#707070;
		}
		
		&:checked +label {
			border-color:rgba(#0740e4, 0.1);
			background:rgba(#0740e4, 0.03);
			color:#0740e4;
		}
	}
}

// type = hidden
.hidden_type {
	overflow: hidden;
	position: absolute;
	margin: -1rem;
	width: 0rem;
	height: 0rem;
	clip: rect(0, 0, 0, 0);
}