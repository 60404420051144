.span_in_input{
	position: absolute;
	top: 14px;
	right: 117px;
	font-size: 14px;
	font-weight: 400;
	color: #707070;
	
	@media (max-width:359px) {
		top: 5px;
		right: 0;
	}
}