/* ----- terms checkbox ----- */
.form{
	padding: 0 16px;
}

.input{
	position: relative;
	
	label{
		color: #101010;
	}
	
	& + .input{
		margin-top: 16px;
	}
	
	&.input_link{
		.link{
			position: absolute;
			top: 50%;
			transform:translateY(-50%);
			right:0;
			margin-top: -1px;
		}
	}
	
	&.check_type {
		label {
			font-size: 14px;
			
			&:before {
				margin-top: 0.1em;
				margin-right: 8px;
			}
		}
		
		input[type=checkbox] {
			&:checked {
				+ label span:before{
					background-position: -40px 0;
				}
			}
		}
	}
	
	&.input_all{
		margin-top: 24px;
		margin-bottom: 24px;
		
		&:first-child{
			margin-top: 0;
		}
		
		label{
			display: block;
			width: 100%;
			height:56px;
			line-height:55px;
			padding-left: 16px;
			border: 1px solid #ededed;
			border-radius:5px;
			font-weight: 600;
			font-size: 16px;
			color: #101010;
			
			&:before {
				margin-right: 8px;
			}
		}
		
		input[type=checkbox] {
			&:checked {
				+ label {
					border-color: rgba(7, 64, 228, 0.1);
					background: rgba(7, 64, 228, 0.03);
					color: #0740e4;
				}
			}
		}
	}
	
	&.input_sub{
		display: inline-block;
		position: relative;
		
		+ .input_sub {
			padding-left: 12px;
		}
		
		label {
			&:before {
				margin-right: 4px;
				width: 16px;
				height: 16px;
			}
		}
		
		input[type=checkbox] {
			+ label:before {
				margin-top: 2px;
				background-size: auto 16px;
				background-position: -48px 0;
			}
			
			&:checked {
				+label:before {
					background-position: -32px 0;
				}
			}
		}
	}
	
	&:not(.input_sub) + .input_sub{
		padding-left: 28px;
		
		&:before{
			content:"";
			display: block;
			position: absolute;
			top: 25%;
			left: 6px;
			width: 8px;
			height: 8px;
			border:1px solid #c9c9c9;
			border-width:0 0 1px 1px;
		}
	}
}



/* ----- terms ----- */
.terms {
	font-size: 13px;
	color:#555555;
	
	b{
		font-wieght:500;
	}
	
	a{
		display: inline-block;
		color:#0740E4;
		text-decoration: underline;
	}
	
	.sort{
		display: block;
	}
	
	.sort_para {
		margin-bottom: 22px;
	}
	
	.sort_table {
		overflow-x: auto;
		margin: 24px 0;
		
		table{
			width: 100%;
			border-bottom:1px solid #d9d9d9;
		}
		
		th, td{
			padding: 16px;
			border:1px solid #D9D9D9;
			border-width:1px 0 0 1px;
			text-align: center;
			
			&:first-child{
				border-left-width:0px;
			}
		}
		
		th{
			font-weight: 500;
			color:#101010;
			background-color: #F8F8F8;
			word-break: keep-all;
		}
		
		td{
			color:#555555;
		}
	}
}

.summary_terms{
	margin-bottom: 60px;
}

.list_terms{
	> li {
		> .sort {
			margin-bottom: 22px;
			font-weight:600;
		}
		
		+ li{
			margin-top: 60px;
		}
		
		> ul{
			> li {
				+ li{
					margin-top: 22px;
				}
			}
		}
		
	}
}

