.icon_group{
  display: block;
  margin:64px 0 48px;
  text-align: center;

  .title {
    display: block;
    margin-bottom:16px;
    color: #101010;
  }

  .box{
    display: block;
    display: flex;
    -webkit-display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
    gap: 16px;
    -webkit-gap: 16px;
    font-size: 0;
  }
}

