:root {
    --vh: 100%;
}

.layout {
    overflow-x:hidden;
    display: block;
    display: flex;
    -webkit-display: flex;
    flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    position: relative;
    
    width: 100%;
    min-width:280px;
    max-width: 600px;
    margin: 0 auto;
    min-height: calc(var(--vh, 1vh) * 100);
    padding-bottom: 48px;
    
    &.header_fixed{
        padding-top: 56px;
    }
    
    .header{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index:10;
        width: 100%;
        background-color: #fff;
        
        .header_inner{
            display: block;
            display: flex;
            -webkit-display: flex;
            align-items: center;
            -webkit-align-items: center;
            width: 100%;
            max-width:600px;
            height: 56px;
            padding: 0 20px;
            margin: 0 auto;
        }
        
        .logo{
            display: block;
            width: 140px;
            height: 33px;
            font-size: 0;
            
            a{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        
        .back{
            display: block;
            width:32px;
            height: 32px;
            margin-left: -12px;
            flex: none;
        }
        
        .title{
            color: #101010;
            flex: 1;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    
    .container{
        display: block;
        display: flex;
        -webkit-display: flex;
        flex: 1;
        -webkit-flex: 1;
        flex-direction: column;
        -webkit-flex-direction: column;
        height: 100%;
        width: 100%;
    }
}

