.useform{
    margin: 0 auto 40px;
}

.field_group{
    width: 100%;
    margin-bottom: 32px;
}

.field_title{
    margin-top: 24px;
    margin-bottom:24px;

    p{
        margin-top: 6px;
    }
}

.field_message{
    display: block;
    flex:none;
    -webkit-flex:none;
    width: 100%;
    font-size: 12px;
    line-height:18px;

    &.error{
        color: #FF673E;
    }
}

.field_icon{
    display: block;
    display: flex;
    -webkit-display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: absolute;
    top:1px;
    right: 0;
}

.header_logo_wrap{
    margin: 52px 0 46px;
}

.header_logo{
    text-align: center;
    
    .logo {
        width:236px;
        height:40px;
        margin:0 auto;
    }
    
    .logo_text {
        display: block;
        margin-top: 8px;
        color: #A7A7A7;
    }
}


