.bottom_floating{
	position: fixed;
	z-index: 10;
	bottom: 0;
	left: 50%;
	width: 100%;
	max-width: 600px; //$adaptive-width-mobile;
	min-width: 280px;
	padding: 32px 16px 16px 16px;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(50%, #FFFFFF)); background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 24%);
	transform: translateX(-50%);
	
	@media (max-width:599px) {
		left: 0;
		transform: none;
	}
}