
.img{
	display: block;
	display: flex;
	-webkit-display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	margin: 0 auto;
	
	img{
		display: block;
		max-width: 100%;
	}
}



