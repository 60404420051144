.modal{
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index:999;
	width: 100%;
	height: 100%;
	
	.mask{
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.6);
	}
	
	.alert{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 312px;
		padding: 24px 24px 23px;
		background-color: #fff;
		border-radius: 5px;
		transform: translate(-50%, -50%);
		
		.button{
			margin-right: -24px;
			margin-top: 31px;
			text-align: right;
			
			button {
				margin-right: 24px;
			}
		}
	}
}