.comp{
	display: block;
	display: flex;
	-webkit-display: flex;
	width: 100%;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	height: 56px;
	line-height:56px;
	border:1px solid transparent;
	border-radius:5px;
	text-align: center;
	
	
	&.solid{
		&.primary{
			color:#fff;
			background-color:#0064FF;
			&:active{
				background-color: #0051D1;
			}
		}
		&.secondary{
			color:#fff;
			background-color: #FF673E;
		}
		&.gray{
			color:#fff;
			background-color:#707070;
		}
		&.white{
			color:#101010;
			background-color:#ffffff;
			&:active{
				background-color: #f8f8f8;
			}
		}
		&.black{
			color:#fff;
			background-color:#000000;
			&:active{
				background-color: #3c3c3c;
			}
		}
		
		&:disabled,
		&[disabled]{
			background: #EDEDED !important;
			color:#fff !important;
		}
	}
	
	&.outline{
		&.primary{
			color:#0740E4;
			border-color: rgba(7, 64, 228, 0.1);
			background-color: rgba(7, 64, 228, 0.03);
			&:active{
				border-color: rgba(7, 64, 228, 0.4);
				background-color: rgba(7, 64, 228, 0.3);
			}
		}
		&.secondary{
			color: #FF673E;
			border-color: rgba(255, 103, 62, 0.1);
			background-color: rgba(255, 103, 62, 0.03);
		}
		&.gray{
			color:#707070;
			border-color:#d9d9d9;
			background-color:#fff;
			&:active{
				border-color: #d9d9d9;
				background-color: #f8f8f8;
			}
		}
		
		&:disabled,
		&[disabled]{
			color:#d9d9d9 !important;
			border-color: #d9d9d9 !important;
			background-color: #fff !important;
		}
	}
	
	&.tiny{
		height: 31px;
	}
	&.small{
		height: 12px;
	}
	&.medium{
		height: 45px;
	}
	&.large{
		height: 56px;
	}
	&.xLarge{
		height: 59px;
	}
	
}
