.notice {
    display: block;
    //display: flex;
    -webkit-display: flex;
    flex: 1;
    -webkit-flex: 1;
    align-items: center;
    -webkit-align-items: center;
    width:100%;
    padding: 0 16px;

    > .inner{
        width: 100%;
        text-align: center;
    }

    .icon {
        display: block;
        margin: 91px auto 16px;
    }

    .title{
        width: 100%;
    }

    .text{
        width: 100%;
        margin-top: 8px;
        margin-bottom: 32px;
    }

}