.links{
    display: block;
    display: flex;
    -webkit-display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top:32px;

    li{
        position: relative;
        padding: 4px 24px 0;
        color: inherit;

        &:first-child{
            padding-left: 0;
        }
    
        &:last-child{
            padding-right: 0;
        }
        
        &:not(:last-child):after {
            content:"";
            display: block;
            position: absolute;
            top: 50%;
            right: -1px;
            width: 1px;
            height: 8px;
            margin-top:-2px;
            background: #c2c2c2;
        }
        
        a{
            color:inherit;
        }
    }
    
}