.qna:not(:first-child){
	margin-top: 32px;
	
	.qna_text{
		position: relative;
		
		&:after{
			content:"";
			display: block;
			position: absolute;
			bottom: 2px;
			left: 0;
			width: 100%;
			height: 1px;
			background:currentColor;
		}
	}
	
	.qna_icon{
		position: relative;
		top: -2px;
		right: 3px;
	}
}