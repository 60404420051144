.comp{
	display: block;
	display: flex;
	-webkit-display: flex;
	margin: 32px auto 0;
	
	&:first-child{
		margin-top: 0;
	}
	
	&.vertical {
		flex-direction: column;
		-webkit-flex-direction: column;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		gap:8px;
		-webkit-gap:8px;
	}
	&.horizontal {
		flex-direction: row;
		-webkit-flex-direction: row;
		flex-wrap: nowrap;
		-webkit-flex-wrap: nowrap;
		align-items: center;
		-webkit-align-items: center;
		gap: 8px;
		-webkit-gap: 8px;
	}
	&.ratio {
		flex-direction: row;
		-webkit-flex-direction: row;
		flex-wrap: nowrap;
		-webkit-flex-wrap: nowrap;
		gap: 8px;
		-webkit-gap: 8px;
		
		> *:first-child {
			width:30%;
			min-width:50px;
		}
	}
}