.list_result{
	border-top:2px solid #101010;
	
	> li{
		padding: 16px 0;
		border-bottom:1px solid #ededed;
		
		.name{
			display: block;
			display: flex;
			-webkit-display: flex;
			align-items: center;
			-webkit-align-items: center;
			gap: 8px;
			-webkit-gap: 8px;
			
			+ .detail{
				margin-top: 4px;
			}
		}
		
	}
}