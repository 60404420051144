
@mixin font-face($name, $file, $range, $weight: noraml, $style: normal) {
    @font-face {
        font-family: '#{$name}';
        font-style: $style;
        font-weight: $weight;
        src: url('../assets/fonts/#{$file}.eot');
        src: local(#{$file}),
            local(#{$file}),
            url('../assets/fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
            url('../assets/fonts/#{$file}.woff2') format('woff2'),
            url('../assets/fonts/#{$file}.woff') format('woff');
        unicode-range: $range;
    }
}

/***
 * 언어 범위 : 한글 - noto sans,
 *           영어, 특수문자 - poppins
 * 폰트 굵기 : 300 ~ 600 (noto, poppins)
 *           300 (light, regular)
 *           400 (regular, regular)
 *           500 (medium, medium)
 *           600 (bold, Semi-bold)
 *           700 (bold, bold)
 ***/

@include font-face('Noto Sans KR', 'notosans-kr-light', 'U+AC00-D7A3', 300);
@include font-face('Noto Sans KR', 'notosans-kr-regular', 'U+AC00-D7A3', 400);
@include font-face('Noto Sans KR', 'notosans-kr-medium', 'U+AC00-D7A3', 500);
@include font-face('Noto Sans KR', 'notosans-kr-bold', 'U+AC00-D7A3', 600);
@include font-face('Noto Sans KR', 'poppins-regular', 'U+0041-005A, U+0061-007A, U+0030-0039,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E', 300);
@include font-face('Noto Sans KR', 'poppins-regular', 'U+0041-005A, U+0061-007A, U+0030-0039,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E', 400);
@include font-face('Noto Sans KR', 'poppins-medium', 'U+0041-005A, U+0061-007A, U+0030-0039,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E', 500);
@include font-face('Noto Sans KR', 'poppins-semiBold', 'U+0041-005A, U+0061-007A, U+0030-0039,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E', 600);
@include font-face('Noto Sans KR', 'poppins-bold', 'U+0041-005A, U+0061-007A, U+0030-0039,U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E', 700);

